import React from "react";
import { Panel } from "components/panel";
import { GetLinksData } from "services";
import { LinkItem } from "pages/usefulLinks/linksList/linkItem";
import "./LinkList.css";

export function LinksList() {
  const linksData = GetLinksData();

  return (
    <Panel className="no-top-padding">
      {linksData.map((link) => {
        return <LinkItem key={link.id} name={link.name} url={link.url} />;
      })}
    </Panel>
  );
}
