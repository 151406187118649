import FeatureData from "assets/files/Features.json";

export function GetFeatureData() {
    let promotedFeatureList = [];
    let featureList = [];
    let premiumFeatureList = [];

    for (let feature of FeatureData) {

        if (feature.icon === "" && feature.description === "") {
            premiumFeatureList.push({
                id: feature.id,
                name: feature.name
            })

            continue;
        }

        featureList.push({
            id: feature.id,
            name: feature.name,
            icon: feature.icon,
            description: feature.description,
            isPremium: feature.isPremium
        })

        if (feature.promoted === true) {
            promotedFeatureList.push({
                id: feature.id,
                name: feature.name,
                icon: feature.icon
            })
        }
    }

    return {
        promotedFeatureList,
        featureList,
        premiumFeatureList
    }
}
