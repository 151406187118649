import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import "./Panel.css";

export function Panel(props) {
  const { id, children, isPrimary, width, height, className } = props;

  return (
    <div
      id={id}
      className={classNames("basic-panel", {
        "primary-panel": isPrimary,
      })}
    >
      <div
        className={"content " + className}
        style={{
          width: width === 0 ? "900px" : width + "px",
          height: height === 0 ? "unset" : height + "px",
        }}
      >
        {children}
      </div>
    </div>
  );
}

Panel.defaultProps = {
  children: <></>,
  isPrimary: false,
  width: 0,
  height: 0,
  id: "",
  className: "",
};

Panel.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  isPrimary: PropTypes.bool,
  width: PropTypes.number,
  height: PropTypes.number,
  id: PropTypes.string,
  className: PropTypes.string,
};
