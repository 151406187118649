import React from "react";
import { Panel } from "components/panel";
import { DropDownTab } from "components/dropDownTab";
import { GetFaqsData } from "services";
import "./faqList.css";

export function FaqsList() {
  const faqsData = GetFaqsData();

  return (
    <Panel>
      {faqsData.map((faq) => {
        return (
          <DropDownTab
            title={
              <span>
                {faq.question}
              </span>
            }
            key={faq.id}
          >
            <ul className="faq-answer">
              {faq.answer.map((answer, index) => {
                return (
                  <li key={index} className="open body-line-height">
                    {answer}
                  </li>
                );
              })}
            </ul>
          </DropDownTab>
        );
      })}
    </Panel>
  );
}
