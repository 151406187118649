import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./Toast.css";

export function Toast(props) {
  const { title, message, toastStyle } = props;

  return (
    <div
      className={classNames("toast-message", {
        success: toastStyle === "success",
        error: toastStyle === "error"
      })}
    >
      <p className="open fs17 fw600 bottom-margin-5">{title}</p>
      <p className="open noBottomMargin">{message}</p>
    </div>
  );
}

Toast.defaultProps = {
  title: "",
  message: "",
  toastStyle: "",
};

Toast.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  toastStyle: PropTypes.string,
};
