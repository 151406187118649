import React from "react";
import PropTypes from "prop-types";

export function ImageTile(props) {
  const { image, title, is64Bit } = props;

  return (
    <div className="item">
      <img
        src={is64Bit ? "data:image/png;base64, " + image : image}
        alt={title}
      />
    </div>
  );
}

ImageTile.defaultProps = {
  image: "",
  title: "",
  is64Bit: false
};

ImageTile.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  is64Bit: PropTypes.bool,
};
