import React from "react";
import { Panel } from "components/panel";
import { ImageTile } from "components/imageTile";
import { GetCompanyData } from "services";
import CulinaLogo from "assets/img/logos/Culina Blue.png";

export function TestimonialsPanel() {
  const companyTestimonials = GetCompanyData();

  return (
    <>
      <Panel isPrimary>
        <h2 className="content-centered fs32 blue">
          “The biggest thing for me is the whole look of the new software – it
          shows how LogisticsUK is evolving with technology to supply members
          with a modern system”
        </h2>
        <p className="content-right colourDarGrey">
          Anna Griffin, Culina Group
        </p>
        <div className="item-row">
          <div className="content-right">
            <ImageTile image={CulinaLogo} title="Culina Group" key="Image 1" />
          </div>
        </div>

        <h2 className="fs28 dark-text-colour fw300">Operators that use our service</h2>
        <div className="item-row">
          {companyTestimonials.map((company) => {
            return (
              <ImageTile
                image={company.icon}
                title={company.name}
                is64Bit
                key={company.id}
              />
            );
          })}
        </div>
      </Panel>
    </>
  );
}
