import React from "react";
import { Panel } from "components/panel";
import { TachoCodeList } from "pages/tachoInfringementCodes/tachoCodeList";

export function TachoInfringementCodesPage() {
  return (
    <>
      <Panel>
        <h2 className="blue fs42">Tacho Infringement Codes</h2>
        <TachoCodeList />
      </Panel>
    </>
  );
}
