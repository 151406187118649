import React from "react";
import { Panel } from "components/panel";
import "./CompliancePanel.css";

export function CompliancePanel() {
  return (
    <>
      <Panel>
        <h2 className="blue">
          Find out how Vision can help you take control of your compliance...
        </h2>
        <div className="column-content open padding-left-90 padding-right-90">
          <p>
            Vision is a compliance management portal and a powerful aggregator,
            drawing together a range of information from existing Logistics UK
            compliance services and third-party data and services, to build a
            broad view of operational transport compliance. Vision offers the
            tools to assist operators in managing driver and vehicle compliance.
            The platform also collates the auditing information from its wide
            range of driver, vehicle and organisational audits into a single
            platform.
          </p>
          <p>
            Vision draws together information from a variety of sources to save
            the operator time and the need to ‘chair swivel’ between multiple
            systems. Where possible Vision looks to create records digitally,
            either through webservices or by extracting data from driver cards
            and vehicle units, this helps to reduce errors in driver and fleet
            records. Vision provides almost instant access to information, once
            an audit, inspection or upload has taken place, so users can quickly
            respond to any issues raised and prevent repeat errors arising in
            the future.
          </p>
        </div>
      </Panel>
    </>
  );
}
