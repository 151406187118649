import React from "react";
import { Panel } from "components/panel";
import Image from "assets/img/support/support-scene.svg";
import "./SupportPage.css";

export function SupportPage() {
    return (
        <>
            <Panel>
                <div className="padding-left-90 padding-right-90">
                    <h2 className="blue fs42">Support</h2>
                    <div className="column-content">
                        <p className="open">
                            Vision has a dedicated tacho support team who are available to
                            help customers with operational issues relating to Vision and can
                            direct drivers’ hours’ queries to the correct people.
                        </p>
                        <h3 className="fs28 fw300">
                            Vision provides full technical and administrative support from
                            9:00 to 5:00, five days a week
                        </h3>

                        <p className="open">
                            To contact our tacho support team call{" "}
                            <span className="bold-text">03700 115 715</span> or email{" "}
                            <a
                                href="mailto: tachosupport@logistics.org.uk"
                                className="bold-text dark-text-colour"
                            >
                                tachosupport@logistics.org.uk {" "}
                            </a>
                            You can also use the feedback tool found in the bottom right-hand
                            corner of any page in Vision.
                        </p>
                    </div>
                </div>
                <div className="banner-image">
                    <div className="banner-image-position">
                        <img src={Image} alt="HGV and DVSA car" className="image-support-scene" />
                    </div>
                </div>
            </Panel>
            <Panel isPrimary>
                <div className="padding-left-90 padding-right-90">
                    <h2 className="fs28 fw300">Legal advice</h2>
                    <h4 className="fs17 open">Member Advice Centre</h4>
                    <div className="column-content open">
                        <p>
                            The Logistics UK’s Member Advice Centre (MAC) is a knowledge hub
                            freely accessible to Logistics UK members. The team consists of
                            experienced transport professionals able to advise on all aspects
                            of transport legislation and operations, including drivers’ hours,
                            operator licensing and dangerous goods. Logistics is one of the
                            UK’s most regulated industries, there are no silly questions when
                            it comes to staying compliant, our team will understand and know
                            how to help.
                        </p>
                        <p>
                            To contact the team, call{" "}
                            <span className="bold-text">0370 60 50 000</span> quoting your
                            member number or email{" "}
                            <a
                                className="bold-text dark-text-colour"
                                href="mailto: MAC@logistics.org.uk"
                            >
                                MAC@logistics.org.uk
                            </a>
                        </p>
                    </div>
                </div>
            </Panel>
        </>
    );
}
