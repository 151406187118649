import React from "react";
import PropTypes from "prop-types";

export function WhatsNewItem(props) {
  const { title } = props;
  
  return (
    <li className="blue">
      <span className="dark-text-colour">{title}</span>
    </li>
  );
}

WhatsNewItem.defaultProps = {
  title: "",
};

WhatsNewItem.propTypes = {
  title: PropTypes.string.isRequired,
};
