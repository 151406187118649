import React from "react";
import { Panel } from "components/panel";
import { FormPanel } from "pages/enquire/formPanel";
import { Toaster } from "react-hot-toast";

export function EnquirePage() {
  return (
    <>
      <Panel>
        <h2 className="blue fs42">Enquire</h2>
        <p className="open body-line-height">
          If you would like to be contacted by our sales team or receive more
          information regarding Vision and its features, please fill out the
          form below. Our team aim to respond to enquiries within 48 hours of it
          being submitted to us.
        </p>
        <FormPanel />
      </Panel>
      <Toaster
        position="bottom-right"
        toastOptions={{
          duration: 5000,
        }}
      />
    </>
  );
}
