import React from "react";
import { LinkToAnchor } from "components/linkToAnchor";
import PropTypes from "prop-types";
import "./KeyServiceTile.css";

export function KeyServiceTile(props) {
  const { image, title } = props;

  return (
    <div className="icon-box">
      <div className="title">
        <img src={"data:image/svg+xml;base64, " + image} alt={title} />
      </div>
      <LinkToAnchor
        path="features"
        anchor={title.replace(/ /g, "")}
        offset={-60}
        animation="smooth"
      >
        <div className="hover-content">
            <p className="fs21">{title}</p>
            <p className="dark-blue open">more&hellip;</p>
        </div>
      </LinkToAnchor>
    </div>
  );
}

KeyServiceTile.defaultProps = {
  image: "",
  title: "",
};

KeyServiceTile.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
};
