import React, { useEffect, useState } from "react";
import { Outlet, Link, useLocation } from "react-router-dom";
import { Footer } from "pages/footer/Footer";
import { useScrollPosition } from "hooks";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import Vision from "assets/img/logos/vision.png";
import "./NavigationBar.css";

export function NavigationBar() {
    const scrollPosition = useScrollPosition();
    const location = useLocation();

    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    function toggleMobileMenu() {
        setMobileMenuOpen((prev) => !prev);
    }

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.href.split("?")[1]);
        let section = queryParams.get("section");
        if (section === null) {
            document.documentElement.scrollTo({
                top: 0,
                left: 0,
                behavior: "instant",
            });
        }

        setMobileMenuOpen(false);

    }, [location]);

    return (
        <>
            <div
                id="header"
                className={classNames({
                    "header-shadow": scrollPosition > 0 || location.pathname !== "/",
                })}
            >
                <div id="header-navigation-container">
                    <button id="mobile-navigation-btn" onClick={toggleMobileMenu}>
                        <div id="mobile-navigation" className={mobileMenuOpen ? "open" : ""}></div>
                    </button>
                    {mobileMenuOpen && (
                        <div id="mobile-menu">
                            <ul id="mobile-menu-navigation">
                                <li>
                                    <Link className="link" to={"/benefits"}>
                                        What we do
                                    </Link>
                                </li>
                                <li>
                                    <Link className="link" to={"/features"}>
                                        Features
                                    </Link>
                                </li>
                                <li>
                                    <Link className="link" to={"/integrations"}>
                                        Integrations
                                    </Link>
                                </li>
                                <li>
                                    <Link className="link" to={"/support"}>
                                        Support
                                    </Link>
                                </li>
                                <li>
                                    <Link className="link" to={"/enquire"}>
                                        Enquire
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    )}
                    

                    <Link
                        to={"/"}
                        className={classNames({
                            "hide-image": scrollPosition === 0 && location.pathname === "/",
                        })}
                    >
                        <img src={Vision} alt="Vision logo" id="header-logo" />
                    </Link>

                    <ul id="header-navigation">
                        <li>
                            <Link className="link" to={"/benefits"}>
                                What we do
                            </Link>
                        </li>
                        <li>
                            <Link className="link" to={"/features"}>
                                Features
                            </Link>
                        </li>
                        <li>
                            <Link className="link" to={"/integrations"}>
                                Integrations
                            </Link>
                        </li>
                        <li>
                            <Link className="link" to={"/support"}>
                                Support
                            </Link>
                        </li>
                        <li>
                            <Link className="link" to={"/enquire"}>
                                Enquire
                            </Link>
                        </li>
                    </ul>

                    <a
                        href="/login"
                        className="dark-blue-btn"
                        id="btn-login"
                    >
                        <span className="mobile-login-text">Login</span>
                        <span className="desktop-login-text">vision login</span>
                        <FontAwesomeIcon icon={faArrowRight} />
                    </a>
                </div>
            </div >
            <Outlet />
            <Footer />
        </>
    );
}
