import React, { useState, useContext, useEffect } from "react";
import { Panel } from "components/panel";
import { MyContext } from "state/MyProvider";
import { isObjEmpty } from "helpers";
import { GetFeatureData } from "services";

export function PremiumFeaturesPanel() {
  const context = useContext(MyContext);
  const [premiumFeatureListFirstHalf, setPremiumFeatureListFirstHalf] =
    useState([]);
  const [premiumFeatureListSecondHalf, setPremiumFeatureListSecondHalf] =
    useState([]);
  const halfWay = 2;

  useEffect(() => {
    if (isObjEmpty(context.featureData)) {
      const keyServices = GetFeatureData();
      context.setFeatureData(keyServices);
      const halfwayThrough = Math.floor(
        keyServices.premiumFeatureList.length / halfWay
      );

      setPremiumFeatureListFirstHalf(
        keyServices.premiumFeatureList.slice(0, halfwayThrough)
      );
      setPremiumFeatureListSecondHalf(
        keyServices.premiumFeatureList.slice(
          halfwayThrough,
          keyServices.premiumFeatureList.length
        )
      );
    } else {
      const halfwayThrough = Math.floor(
        context.featureData.premiumFeatureList.length / halfWay
      );

      setPremiumFeatureListFirstHalf(
        context.featureData.premiumFeatureList.slice(0, halfwayThrough)
      );
      setPremiumFeatureListSecondHalf(
        context.featureData.premiumFeatureList.slice(
          halfwayThrough,
          context.featureData.premiumFeatureList.length
        )
      );
    }
  }, [context]);

  return (
    <Panel isPrimary>
      <p className="fs28 fw300">Additional premium features</p>
      <div className="column-container">
        <div className="column">
          <div className="column-content">
            {premiumFeatureListFirstHalf.map((premiumFeature) => {
              return (
                <p
                  className="fs28 blue no-bottom-margin fw300"
                  key={premiumFeature.id}
                >
                  {premiumFeature.name}
                </p>
              );
            })}
          </div>
        </div>
        <div className="column">
          <div className="column-content">
            {premiumFeatureListSecondHalf.map((premiumFeature) => {
              return (
                <p
                  className="fs28 blue no-bottom-margin fw300"
                  key={premiumFeature.id}
                >
                  {premiumFeature.name}
                </p>
              );
            })}
          </div>
        </div>
      </div>
    </Panel>
  );
}
