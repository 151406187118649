const ValidStatusCode = 201;

export async function PostEnquireApi(postData) {
    const settings = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(postData)
    };

    try {
        const url = `${window.env.APIMURL}system/enquire`;
        const fetchResponse = await fetch(url, settings);
        return fetchResponse.status === ValidStatusCode
    } catch (error) {
        return false;
    }
}
