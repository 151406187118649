import React, { useContext, useEffect, useState } from "react";
import { Panel } from "components/panel";
import { KeyServiceTile } from "pages/home/keyServiceAreasPanel/keyServiceTile";
import { GetFeatureData } from "services";
import { MyContext } from "state/MyProvider";
import { isObjEmpty } from "helpers";
import { Link } from "react-router-dom";
import "./KeyServiceAreasPanel.css";

export function KeyServiceAreasPanel() {
  const context = useContext(MyContext);
  const [promotedKeyServices, setPromotedKeyServices] = useState([]);

  useEffect(() => {
    if (isObjEmpty(context.featureData)) {
      const keyServices = GetFeatureData();
      context.setFeatureData(keyServices);
      setPromotedKeyServices(keyServices.promotedFeatureList);
    } else {
      setPromotedKeyServices(context.featureData.promotedFeatureList);
    }
  }, [context]);

  return (
    <Panel isPrimary>
      <h2 className="content-centered fs28 fw300">Key service areas</h2>
      <div className="icon-box-row">
        {promotedKeyServices.map((service) => {
          return (
            <KeyServiceTile
              image={service.icon}
              title={service.name}
              key={service.id}
            />
          );
        })}
      </div>
      <div className="content-centered">
        <Link className="btn light-blue-btn" to={"/features"}>
          More features
        </Link>
      </div>
    </Panel>
  );
}
