import React from "react";
import { Panel } from "components/panel";
import { LinksList } from "./linksList";

export function UsefulLinks() {
  return (
    <Panel>
      <h2 className="blue fs42">Useful Links</h2>
      <LinksList />
    </Panel>
  );
}
