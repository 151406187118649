import React from "react";
import PropTypes from "prop-types";
import { HashLink as Link } from "react-router-hash-link";

export function LinkToAnchor(props) {
  const { linkText, path, anchor, offset, animation, className, children } =
    props;

  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.scrollY;
    const yOffset = offset;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: animation });
  };
  if (linkText !== "") {
    return (
      <Link
        to={`/${path}#${anchor}`}
        scroll={(el) => scrollWithOffset(el)}
        className={className}
      >
        {linkText}
      </Link>
    );
  } else {
    return (
      <Link to={`/${path}#${anchor}`} scroll={(el) => scrollWithOffset(el)}>
        {children}
      </Link>
    );
  }
}

LinkToAnchor.defaultProps = {
  linkText: "",
  path: "",
  anchor: "",
  offset: 0,
  animation: "",
  className: "",
  children: <></>,
};

LinkToAnchor.propTypes = {
  linkText: PropTypes.string,
  path: PropTypes.string,
  anchor: PropTypes.string,
  offset: PropTypes.number,
  animation: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};
