import React from "react";
import { Panel } from "components/panel/Panel";
import { BenefitsList } from "./benefitsList";

export function BenefitsPage() {
  return (
    <>
      <Panel>
        <h2 className="blue fs42">What we do</h2>
        <div className="column-content">
          <p className="open">
            Logistics UK has developed a powerful platform that allows users to
            manage their driver and fleet compliance via a single log in. Born
            from decades of experience leading the industry and our clients’
            driver and fleet compliance, Vision has been specifically designed
            to increase visibility of your operations compliance with KPI’s that
            affect your operator Licence.
          </p>
          <h3 className="fs28 fw300">
            Vision is effective at all levels of a Logistics business. Find out
            how Vision can support your role.
          </h3>
        </div>
      </Panel>
      <BenefitsList />
    </>
  );
}
