import React from "react";
import { Link } from "react-router-dom";
import { Panel } from "components/panel";
import { ImageTile } from "components/imageTile";
import { GetIntegrationData } from "services";
import "./IntegrationsPanel.css";

export function IntegrationsPanel() {
  const promotedIntegrationData = GetIntegrationData().promotedIntegrations;
  return (
    <Panel>
      <h2 className="fs28 fw300">Integrations that Vision supports</h2>
      <div className="item-row">
        {promotedIntegrationData.map((integration) => {
          return (
            <ImageTile
              image={integration.icon}
              title={integration.name}
              is64Bit
              key={integration.id}
            />
          );
        })}
      </div>
      <div className="content-centered">
        <Link className="btn blue-btn" to={"/integrations"}>
          More integrations
        </Link>
      </div>
    </Panel>
  );
}
