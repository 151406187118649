import React from 'react';
import ReactDOM from 'react-dom/client';
import { App } from './pages/app/App';
import '@blueprintjs/core/lib/css/blueprint.css';
import './style/index.css';

fetch(process.env.PUBLIC_URL + '/env.json', {
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  }
}).then(response => {
  if (!response.ok) {
    throw new Error("Could not load settings, please reload");
  }
  return response.json();
}).then(json => {

  window.env = json;

  const root = ReactDOM.createRoot(document.getElementById('root'));

  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );

});