import IntegrationsData from "assets/files/Integrations.json";

export function GetIntegrationData() {
    let promotedIntegrations = [];
    let integrations = IntegrationsData;

    for (let integration of IntegrationsData) {
        promotedIntegrations = promotedIntegrations.concat(integration.thirdParties.filter(i => i.promoted === true));
    }

    return {
        promotedIntegrations,
        integrations
    }
}