import React from "react";
import PropTypes from "prop-types";
import "./IntegrationsImage.css";

export function IntegrationsImage(props) {
  const { icon, name } = props;

  return (
    <div className="list-item">
      <img
        className="gallery-image-width"
        src={"data:image/png;base64, " + icon}
        alt={name}
      />
    </div>
  );
}

IntegrationsImage.defaultProps = {
  icon: "",
  name: "",
};

IntegrationsImage.propTypes = {
  icon: PropTypes.string,
  name: PropTypes.string,
};
