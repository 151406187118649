import React from "react";
import { Panel } from "components/panel";
import "./BenefitsPanel.css";

export function BenefitsPanel() {
  return (
    <Panel>
      <div className="padding-left-90 padding-right-90">
        <h2 className="fs28 fw300">What are the benefits?</h2>
        <div className="benefits-section">
          <div className="benefits-column">
            <div className="benefits-segment open right-padding15">
              <p className="fs17 fw600">Control your compliance</p>
              <p className="body-line-height">
                Vision is the leading driver and fleet compliance platform,
                developed by the industry experts in compliance.
              </p>
            </div>
          </div>
          <div className="benefits-column">
            <div className="benefits-segment open left-padding15">
              <p className="fs17 fw600">Cut costs</p>
              <p className="body-line-height">
                A single view of your compliance not only saves time, but
                provides savings where you may have been using multiple systems
                to gain management information.
              </p>
            </div>
          </div>
          <div className="benefits-column">
            <div className="benefits-segment open right-padding15">
              <p className="fs17 fw600">Drive efficiency</p>
              <p className="body-line-height">
                Achieve transparency by bringing your data sources into one
                dashboard. Vision’s intuitive user interface and powerful
                analytics tools make it faster and easier than ever to manage,
                control and analyse all of your compliance data, from whatever
                source.
              </p>
            </div>
          </div>
          <div className="benefits-column">
            <div className="benefits-segment open left-padding15">
              <p className="fs17 fw600">Benchmark your operation</p>
              <p className="body-line-height">
                Users can benchmark data both internally and externally, across
                multiple locations and peer groups - making it easy to identify
                where savings can be made or performance improved.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Panel>
  );
}
