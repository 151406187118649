import React, { useContext, useEffect, useState } from "react";
import { IntegrationsImage } from "pages/integrations/integrationsGallery/integrationsImage";
import { GetIntegrationData } from "services";
import { MyContext } from "state";
import { isObjEmpty } from "helpers";
import "./IntegrationsGallery.css";
import { Panel } from "components/panel";

export function IntegrationsGallery() {
  const context = useContext(MyContext);
  const [integrations, setIntegrations] = useState([]);

  useEffect(() => {
    if (isObjEmpty(context.integrationData)) {
      const integrationData = GetIntegrationData();
      context.setIntegrationData(integrationData);
      setIntegrations(integrationData.integrations);
    } else {
      setIntegrations(context.integrationData.integrations);
    }
  }, [context]);

  return (
    <Panel isPrimary>
      {integrations.map((integration) => {
        return (
          <div key={integration.id}>
            <p className="fs28 fw300">{integration.type}</p>
            <div className="list end-of-list-padding">
              {integration.thirdParties.map((thirdParty) => {
                return (
                  <IntegrationsImage
                    icon={thirdParty.icon}
                    name={thirdParty.name}
                    key={thirdParty.id}
                  />
                );
              })}
            </div>
          </div>
        );
      })}
    </Panel>
  );
}
