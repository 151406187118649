import React from "react";
import { Panel } from "components/panel";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./ImagePanel.css";

export function ImagePanel(props) {
  const { id, image, imageClassNames, isPrimary, children } = props;

  return (
    <Panel id={id} isPrimary={isPrimary}>
          <div className={classNames("wrap content-fit", {'reverse': props.isPrimary})} >
        <div
          className={classNames("wrap image-fit", {
            hide: !isPrimary,
          })}
        >
          <img
            className={imageClassNames + " image-right-padding"}
            src={"data:image/svg+xml;base64, " + image}
            alt={props.title }
          />
        </div>
        {children}
        <div
          className={classNames("wrap image-fit", {
            hide: isPrimary,
          })}
        >
          <img
            className={imageClassNames + " image-left-padding"}
            src={"data:image/svg+xml;base64, " + image}
            alt={props.title}
          />
        </div>
      </div>
    </Panel>
  );
}

ImagePanel.defaultProps = {
  id: "",
  image: "",
  imageClassNames: "",
  isPrimary: false,
  children: <></>,
};

ImagePanel.propTypes = {
  id: PropTypes.string,
  image: PropTypes.string,
  title: PropTypes.string,
  imageClassNames: PropTypes.string,
  isPrimary: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
