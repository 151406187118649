import React from "react";
import { Panel } from "components/panel";
import Video from "assets/img/video/vision-intro.mp4";
import thumbnail from "assets/img/video/vision-intro-thumb.jpg";
import "./VideoPanel.css";

export function VideoPanel() {
  return (
    <Panel isPrimary>
      <video className="video-promo" controls src={Video} poster={thumbnail} />
    </Panel>
  );
}
