import React from "react";
import { Link } from "react-router-dom";
import ErrorImage from "assets/img/error/errorImage.png";
import Vision from "assets/img/logos/vision.png";
import "./ErrorPage.css";

export function ErrorPage() {
  return (
    <>
      <div id="header" className="header-shadow">
        <div id="header-navigation-container">
          <Link to={"/"}>
            <img src={Vision} alt="Vision logo" id="header-logo" />
          </Link>
        </div>
      </div>
      <div className="center-layout">
        <div className="center-text light-blue">
          <div>
            <img src={ErrorImage} alt="Error Icon" />
          </div>
          <h1 className="fs42 fw500 top-margin">
            Sorry, but there appears to be a problem…
          </h1>
          <p className="open">
            The page you are looking for either doesn’t exist or an error has
            occurred. Please click the link to go back to the{" "}
            <Link className="underline" to={"/"}>
              Homepage
            </Link>
          </p>
        </div>
      </div>
    </>
  );
}
