import React from "react";
import { Panel } from "components/panel";
import { Link } from "react-router-dom";
import "./LearnMorePanel.css";

export function LearnMorePanel() {
  return (
    <Panel>
      <h2 className="content-centered heading fs42">
        Want to learn more? Click the button below ...
      </h2>
      <div className="content-centered">
        <Link className="btn blue-btn" to={"/enquire"}>
          Enquire today
        </Link>
      </div>
    </Panel>
  );
}
