import React from "react";
import Logo from "assets/img/logisticsuk-logo.png";
import "pages/footer/Footer.css";
import { Link } from "react-router-dom";

export function Footer() {
  return (
    <div id="footer">
      <div id="footer-menu">
        <div className="content">
          <div id="footer-menu-container">
            <div className="footer-menu-list">
              <h3>Uploading to Vision</h3>
              <ul>
                <li>
                  <Link to={"/vision-uploader"}>Vision Uploader</Link>
                </li>
                <li>
                  <a href="https://shop.logistics.org.uk" target="_blank">
                    Shop
                  </a>
                </li>
                <li>
                  <Link to={"/integrations"}>Integrations</Link>
                </li>
                <li>
                  <a
                    href="https://logistics.org.uk/vision-help-site#Visionhelpguides"
                    target="_blank"
                  >
                    Vision updates
                  </a>
                </li>
              </ul>
            </div>
            <div className="footer-menu-list">
              <h3>Help and support</h3>
              <ul>
                <li>
                  <Link to={"/faqs"}>FAQs</Link>
                </li>
                <li>
                  <Link to={"/useful-links"}>Useful links</Link>
                </li>
                <li>
                  <Link to={"/tacho-code-key"}>Tacho code keys</Link>
                </li>
                <li>
                  <a href="https://logisticsuk.statuspage.io/" target="_blank">
                    Vision status
                  </a>
                </li>
              </ul>
            </div>
            <div className="footer-menu-list">
              <h3>About us</h3>
              <ul>
                <li>
                  <a
                    href="https://logistics.org.uk/about-us/policies-and-certificates/fta-privacy-policy"
                    target="_blank"
                  >
                    Privacy
                  </a>
                </li>
                <li>
                  <Link to={"/enquire"}>Contact us</Link>
                </li>
                <li>
                  <a
                    href="https://logistics.org.uk/about-us/policies-and-certificates/fta-cookie-policy"
                    target="_blank"
                  >
                    Cookie policy
                  </a>
                </li>
                <li>
                  <a
                    href="https://logistics.org.uk/about-us/policies-and-certificates/fta-website-terms-of-use"
                    target="_blank"
                  >
                    Terms and conditions
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div id="footer-copyright">
        <div className="content">
          <div id="footer-copyright-text">
            <p>Service provided by</p>
            <img id="logistics-logo" src={Logo} />
            <p id="copyright-text">
              &copy; Copyright 2008-2023 Logistics UK (a trading name of FTA){" "}
              <br />
              Logistics UK is a trading name of Freight Transport Association
              Limited <br />
              Registered Office - Hermes House, St John’s Road Tunbridge Wells
              Kent TN4 9UZ <br />
              Registered in England Number 391957
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
