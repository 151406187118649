import React from "react";
import { Panel } from "components/panel";
import { PremiumFeaturesPanel } from "pages/features/premiumFeaturesPanel";
import { FeaturesList } from "pages/features/featuresList";
import { Link } from "react-router-dom";

export function FeaturesPage() {
  return (
    <>
      <Panel>
        <div>
          <h2 className="blue fs42">Features</h2>
          <div className="column-content open">
            <p>
              Vision has many features to help support your organisation to
              comply with the complex rules and regulations required to maintain
              your operator licence and keep on top of your compliance. Our
              single log in platform visualises from a variety of Logistics UK
              services and provides your business with one place to view your
              results that help you to remain compliant with the requirements of
              your Operator Licence.
            </p>
          </div>
        </div>
      </Panel>
      <FeaturesList />
      <PremiumFeaturesPanel />
      <Panel>
        <h2 className="content-centered heading fs32">
          For further details and a full list of integrations please select the
          appropriate option when on the Enquiries form page…
        </h2>
        <div className="content-centered">
          <Link className="btn blue-btn" to={"/enquire"}>
            Enquire today
          </Link>
        </div>
      </Panel>
    </>
  );
}
