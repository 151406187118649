import React from "react";
import { Title } from "pages/home/title/Title";
import { CompliancePanel } from "pages/home/compliancePanel/CompliancePanel";
import { TestimonialsPanel } from "pages/home/testimonialsPanel";
import { KeyServiceAreasPanel } from "pages/home/keyServiceAreasPanel/KeyServiceAreasPanel";
import { BenefitsPanel } from "pages/home/benefitsPanel/BenefitsPanel";
import { VideoPanel } from "pages/home/videoPanel/VideoPanel";
import { LearnMorePanel } from "pages/home/learnMorePanel/LearnMorePanel";
import { IntegrationsPanel } from "pages/home/integrationsPanel/IntegrationsPanel";


export function HomePage() {
  return (
    <>
      <Title />
      <CompliancePanel />
      <TestimonialsPanel />
      <BenefitsPanel />
      <KeyServiceAreasPanel />
      <LearnMorePanel />
      <VideoPanel />
      <IntegrationsPanel />
    </>
  );
}
