import React, { useState, useMemo } from "react";

export const MyContext = React.createContext({});

export function MyProvider(props) {
    const [featureData, setFeatureData] = useState({});
    const [integrationData, setIntegrationData] = useState({});

    const value = useMemo(() => ({
        featureData,
        setFeatureData,
        integrationData,
        setIntegrationData
    }), [featureData, integrationData]);

    return (
        <MyContext.Provider value={value}>
            {props.children}
        </MyContext.Provider>
    )
}