import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Panel } from "components/panel";
import { DropDownTab } from "components/dropDownTab";
import { GetTachoCodesData } from "services";
import "./TachoCodeList.css";

export function TachoCodeList() {
  const TachCodesData = GetTachoCodesData();
  const { hash } = useLocation();
  const barSpacing = 80;

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.href.split("?")[1]);
    let section = queryParams.get("section");

    if (section !== null) {
      const element = document.getElementById(section.toLowerCase());

      if (element) {
        document.documentElement.scrollTo({
          top: element.getBoundingClientRect().top - barSpacing,
          left: 0,
          behavior: "smooth",
        });

        element.click();
        document.getElementById(section.toLowerCase() + "law").click();
      }
    }
  }, [hash]);

  return (
    <Panel>
      {TachCodesData.map((tachoCode) => {
        return (
          <DropDownTab
            key={tachoCode.id}
            id={tachoCode.code}
            title={
              <span className="dropdown-title-alignment">
                <span className="fs28 fw300">
                  {tachoCode.code.toUpperCase()}
                </span>
                <span className="fs17 fw500 left-spacing">
                  {tachoCode.title}
                </span>
              </span>
            }
          >
            <DropDownTab
              title={<span className="fs17">What does the law say?</span>}
              id={tachoCode.code + "law"}
            >
              {tachoCode.law.map((law, index) => {
                return <p key={index}>{law}</p>;
              })}
            </DropDownTab>
            <DropDownTab
              title={
                <span className="fs17">What are the potential penalties?</span>
              }
            >
              {tachoCode.penalties?.map((penalty) => {
                return (
                  <div key={penalty.id}>
                    <p className="fs17 fw500">{penalty.fine}</p>
                    <div>
                      {penalty.description.map((description, index) => {
                        return <p key={index}>{description}</p>;
                      })}
                    </div>
                    {penalty.disclaimer && (
                      <p>
                        * We have identified what we believe to be the most
                        likely fixed penalty to be attributed to this offence.
                        However it should be remembered that enforcement
                        officers will decide on the actual offence given the
                        full circumstances at the time, and that whether to
                        offer a fixed penalty (rather than a verbal warning or
                        to refer the matter to court) is entirely at their
                        discretion.
                      </p>
                    )}
                  </div>
                );
              })}
            </DropDownTab>
            {tachoCode.infringements !== null && (
              <DropDownTab
                title={
                  <span className="fs17">
                    What further infringements can occur?
                  </span>
                }
              >
                {tachoCode.infringements?.map((infringement) => {
                  return (
                    <div key={infringement.id}>
                      <p>{infringement.description}</p>

                      {infringement.listing.map((infringementListing) => {
                        return (
                          <div key={infringementListing.id}>
                            <p className="fs17 fw500">
                              {infringementListing.type}
                            </p>
                            <p>{infringementListing.description}</p>
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </DropDownTab>
            )}
            <DropDownTab
              title={
                <span className="fs17">
                  What should be done about it? Advice from Logistics UK
                </span>
              }
            >
              {tachoCode.advice?.map((advice, adviceIndex) => {
                return (
                  <div key={adviceIndex}>
                    <p>{advice.information}</p>
                    {advice.extra !== null && (
                      <ul>
                        {advice.extra.map((extra, adviceExtraIndex) => {
                          return <li key={adviceExtraIndex}>{extra}</li>;
                        })}
                      </ul>
                    )}
                  </div>
                );
              })}
            </DropDownTab>
          </DropDownTab>
        );
      })}
    </Panel>
  );
}
