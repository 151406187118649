import React from "react";
import { Panel } from "components/panel";
import { WhatsNewItem } from "pages/visionUploader/whatsNewItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWindows } from "@fortawesome/free-brands-svg-icons";
import { GetNewFeaturesData } from "services";
import "./VisionUploader.css";

export function VisionUploader() {
  const newFeatureData = GetNewFeaturesData();

  return (
    <>
      <Panel>
        <h2 className="blue fs42">Vision Uploader</h2>
        <div className="column-content open">
          <p className="fs17 open fw600">Upgrade today!</p>
          <p className="open">
            Vision is being upgraded to comply with the latest security
            standards. These enhancements will ensure we keep your data safe and
            secure and will enable us to offer single sign-on.
          </p>
          <p className="open">
            The new Vision Uploader is already available for you to download. To
            set this up follow the instructions on the installation wizard. Once
            installed, we advise you to uninstall any older versions of the
            uploader that you have on your PC.
          </p>
        </div>
      </Panel>
      <Panel isPrimary>
        <div className="center-text">
          <p className="fs32 blue fw500 title-bottom-margin">
            Download Vision Uploader
          </p>
          <p className="blue open">
            For Windows{" "}
            <FontAwesomeIcon
              size="xl"
              className="windows-icon-padding"
              icon={faWindows}
            />
          </p>
          <a
            href="https://live-loguk-employee-api.azurewebsites.net/Files/Uploader/Vision_Uploader_Setup.msi"
            target="_blank"
          >
            <button className="btn light-blue-btn fs16 museo cursor-pointer">
              Download Installer
            </button>
          </a>
        </div>
      </Panel>
      <Panel>
        <p className="fs17 fw600">
          What do I get from the new Vision Uploader?
        </p>
        <p className="open">
          This is the best driver card upload software that we have ever
          developed. The new uploader offers the following features:
        </p>
        <ul className="fs14 open feature-line-height">
          {newFeatureData.map((feature) => {
            return <WhatsNewItem title={feature.title} key={feature.id} />;
          })}
        </ul>
        <p className="open">
          If you have any difficulties installing the uploader please contact {" "}
          <a
            href="mailto: tachosupport@logistics.org.uk"
            className="bold-text dark-text-colour"
          >
            tachosupport@logistics.org.uk
          </a>{" "}
          or call on <span className="bold-text">01892 552377</span>.
        </p>
      </Panel>
    </>
  );
}
