import React from "react";
import { Panel } from "components/panel";
import { FaqsList } from "pages/faqs/faqsList";

export function FaqsPage() {
  return (
    <Panel>
      <h2 className="blue fs42">FAQs</h2>
      <FaqsList />
    </Panel>
  );
}
