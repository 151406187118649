import React from "react";
import { Panel } from "components/panel";
import { IntegrationsGallery } from "./integrationsGallery";
import { Link } from "react-router-dom";

export function IntegrationsPage() {
  return (
    <>
      <Panel>
        <h2 className="blue fs42">Integrations</h2>
        <div className="column-content open">
          <p>
            We offer an API that enables external software to integrate with
            Vision by automatically pushing data such as driver licence
            information or tachograph data to the relevant parts of Vision.
            Examples of current integrations are listed below:
          </p>
        </div>
      </Panel>
      <IntegrationsGallery />
      <Panel>
        <h2 className="content-centered heading fs32">
          For further details and a full list of integrations please select the
          appropriate option when on the Enquiries form page…
        </h2>
        <div className="content-centered">
          <Link className="btn blue-btn" to={"/enquire"}>
            Enquire today
          </Link>
        </div>
      </Panel>
    </>
  );
}
