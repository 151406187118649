import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import VisionLogo from "assets/img/logos/vision-title.png";
import background from "assets/img/home-animation/background.svg";
import Sun from "assets/img/home-animation/sun.svg";
import Tree1 from "assets/img/home-animation/tree-1.svg";
import Tree2 from "assets/img/home-animation/tree-2.svg";
import Coach from "assets/img/home-animation/coach.svg";
import LargeWheel from "assets/img/home-animation/wheel-large.svg";
import HGV from "assets/img/home-animation/hgv.svg";
import Van from "assets/img/home-animation/van.svg";
import SmallWheel from "assets/img/home-animation/wheel-small.svg";
import Bush1 from "assets/img/home-animation/bush-1.svg";
import Bush2 from "assets/img/home-animation/bush-2.svg";
import anime from "animejs/lib/anime.es.js";
import "./Title.css";

export function Title() {

    let animations = [];

    function startAnimation() {

        if (animations.length === 0) {
            //initial setup

            animatePSV();
            animateVan();
            animateTree1();
            animateTree2();
            animateBush1();
            animateBush2();
            animateWheels();
            animateSmallWheels();
            backgroundScroll();

        }
    }

    function animatePSV() {
        animations.push(anime({
            targets: "#asset-psv",
            left: '100%',
            marginLeft: '500px',
            loop: true,
            duration: 20000,
            easing: "linear",
        }));
    }

    function animateVan() {
        animations.push(anime({
            targets: "#asset-van",
            left: '100%',
            marginLeft: '300px',
            loop: true,
            delay: 2000,
            duration: 11000,
            easing: "linear",
        }));
    }

    function animateTree1() {
        animations.push(anime({
            targets: "#asset-tree-1",
            right: '100%',
            marginRight: '300px',
            loop: true,
            duration: 19000,
            delay: 7000,
            easing: "linear",
        }));
    }

    function animateTree2() {
        animations.push(anime({
            targets: "#asset-tree-2",
            right: '100%',
            marginRight: '250px',
            loop: true,
            duration: 19000,
            delay: 3000,
            easing: "linear",
        }));
    }

    function animateBush1() {
        animations.push(anime({
            targets: "#asset-bush-1",
            right: '100%',
            marginRight: '300px',
            loop: true,
            duration: 10000,
            delay: 1000,
            easing: "linear",
        }));
    }

    function animateBush2() {
        animations.push(anime({
            targets: "#asset-bush-2",
            right: '100%',
            marginRight: '300px',
            loop: true,
            duration: 10000,
            delay: 5000,
            easing: "linear",
        }));
    }

    function animateWheels() {
        animations.push(anime({
            targets: ".asset-wheel",
            rotate: "+=360",
            duration: 2000,
            easing: "linear",
            loop: true,
        }));
    }

    function animateSmallWheels() {
        animations.push(anime({
            targets: ".asset-wheel-small",
            rotate: "+=360",
            duration: 1000,
            easing: "linear",
            loop: true,
        }));
    }

    function backgroundScroll() {
        animations.push(anime({
            targets: "#hero-animation-bg",
            backgroundPositionX: "-=8000px",
            duration: 60000,
            easing: "linear",
            complete: backgroundScroll,
        }));
    }

    useEffect(startAnimation, []);



    useEffect(() => {


    }, []);

    return (
        <div className="basic-panel" >
            <div className="panel-light">
                <div id="hero-animation">
                    <img src={VisionLogo} alt="Vision logo" id="asset-vision-logo" />
                    <img src={Sun} alt="Sun" className="animation-asset" id="asset-sun" />
                    <img
                        src={Tree1}
                        alt="Tree 1"
                        className="animation-asset"
                        id="asset-tree-1"
                    />
                    <img
                        src={Tree2}
                        alt="Tree 2"
                        className="animation-asset"
                        id="asset-tree-2"
                    />
                    <div
                        id="hero-animation-bg"
                        style={{ backgroundImage: `url(${background})` }}
                    ></div>

                    <div className="animation-asset" id="asset-hgv">
                        <img src={HGV} alt="HGV" height="190" />
                        <img
                            src={LargeWheel}
                            className="asset-wheel asset-wheel-1"
                            alt="Large wheel"
                        />
                        <img
                            src={LargeWheel}
                            className="asset-wheel asset-wheel-2"
                            alt="Large wheel"
                        />
                        <img
                            src={LargeWheel}
                            className="asset-wheel asset-wheel-3"
                            alt="Large wheel"
                        />
                        <img
                            src={LargeWheel}
                            className="asset-wheel asset-wheel-4"
                            alt="Large wheel"
                        />
                        <img
                            src={LargeWheel}
                            className="asset-wheel asset-wheel-5"
                            alt="Large wheel"
                        />
                        <img
                            src={LargeWheel}
                            className="asset-wheel asset-wheel-6"
                            alt="Large wheel"
                        />
                    </div>
                    <div className="animation-asset" id="asset-psv">
                        <img src={Coach} alt="Coach" height="155" />
                        <img
                            src={LargeWheel}
                            className="asset-wheel asset-wheel-1"
                            alt="Large wheel"
                        />
                        <img
                            src={LargeWheel}
                            className="asset-wheel asset-wheel-2"
                            alt="Large wheel"
                        />
                    </div>
                    <div className="animation-asset" id="asset-van">
                        <img src={Van} alt="Van" height="122" />
                        <img
                            src={SmallWheel}
                            className="asset-wheel-small asset-wheel-1"
                            alt="Small wheel"
                        />
                        <img
                            src={SmallWheel}
                            className="asset-wheel-small asset-wheel-2"
                            alt="Small wheel"
                        />
                    </div>
                    <img
                        src={Bush1}
                        alt="Bush 1"
                        className="animation-asset"
                        id="asset-bush-1"
                    />
                    <img
                        src={Bush2}
                        alt="Bush 2"
                        className="animation-asset"
                        id="asset-bush-2"
                    />
                </div>
                <div className="content hero-content">
                    <h2>
                        Logistics UK’s in-house cutting-edge platform allowing users to
                        manage driver and fleet compliance via a single portal.
                    </h2>
                    <p className="padding-left-90 padding-right-90">
                        Born from decades of experience leading the industry and our
                        clients’ driver and fleet compliance, Vision has been specifically
                        designed to incorporate insights from our industry-leading services.
                    </p>
                    <div className="content-centered">
                        <Link className="btn light-blue-btn" to={"/enquire"}>
                            Enquire today
                        </Link>
                    </div>
                </div>
            </div>
            <div className="content-centered">
                <div className="arrow-down"></div>
            </div>
        </div>
    );
}
