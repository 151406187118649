import React from "react";
import { GetCaseStudyData } from "services";
import { ImagePanel } from "components/imagePanel";

export function BenefitsList() {
  const caseStudies = GetCaseStudyData();
  const half = 2;

  return (
    <>
      {caseStudies.map((caseStudy, caseStudyIndex) => {
        return (
          <ImagePanel
            key={caseStudy.id}
            image={caseStudy.image}
            imageClassNames="image-height-220"
            isPrimary={caseStudyIndex % half === 0}
          >
            <div className="text-wrap">
              <p className="fs28 fw300">{caseStudy.role}</p>
              {caseStudy.description.map((description, descriptionIndex) => {
                return (
                  <p key={descriptionIndex} className="open">
                    {description}
                  </p>
                );
              })}
            </div>
          </ImagePanel>
        );
      })}
    </>
  );
}
