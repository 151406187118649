import React, { useContext, useEffect, useState } from "react";
import { MyContext } from "state/MyProvider";
import { isObjEmpty } from "helpers";
import { GetFeatureData } from "services";
import { ImagePanel } from "components/imagePanel/ImagePanel.jsx";
import classNames from "classnames";

export function FeaturesList() {
  const context = useContext(MyContext);
  const [features, setFeatures] = useState([]);
  const half = 2;

  useEffect(() => {
    if (isObjEmpty(context.featureData)) {
      const keyServices = GetFeatureData();
      context.setFeatureData(keyServices);
      setFeatures(keyServices.featureList);
    } else {
      setFeatures(context.featureData.featureList);
    }
  }, [context]);

  return (
    <>
      {features.map((feature, index) => {
        return (
          <ImagePanel
            id={feature.name.replace(/ /g, "")}
            image={feature.icon}
            key={feature.id}
            imageClassNames="image-height-170"
            isPrimary={index % half === 0}
          >
            <div className="text-wrap">
              <p
                className={classNames("blue fs17 open fw600", {
                  hide: !feature.isPremiumFeature,
                })}
              >
                Premium Feature
              </p>
              <p className="fs28 fw300">{feature.name}</p>
              <p className="open body-line-height">{feature.description}</p>
            </div>
          </ImagePanel>
        );
      })}
    </>
  );
}
