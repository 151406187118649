import React, { useState } from "react";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";
import "./DropDownTab.css";

export function DropDownTab(props) {
  const { title, id, children } = props;
  const [toggle, setToggle] = useState(false);

  return (
    <>
      <button
        className="wrap cursor-pointer even-height drop-down-header"
        onClick={() => setToggle(!toggle)}
        id={id}
      >
        {title}
        <div className="button-spacing">
          <FontAwesomeIcon
            className={classNames("arrow-button", {
              "fa-rotate-90": toggle,
            })}
            icon={faAngleRight}
            size="2xl"
          />
        </div>
      </button>
      <hr />

      <div
        className={classNames("down-spacing", {
          hide: !toggle,
        })}
      >
        {children}
      </div>
    </>
  );
}

DropDownTab.defaultProps = {
  title: "",
  id: "",
  children: <></>,
};

DropDownTab.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  id: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
