import React from "react";
import PropTypes from "prop-types";
import "./LinkItem.css";

export function LinkItem(props) {
  const { name, url } = props;

  return (
    <>
      <p className="fs17 fw600">{name}</p>
      <a
        className="open dark-text-colour fs14 underline-link"
        href={url}
        target="_blank"
      >
        {url}
      </a>
      <div className="link-spacing"></div>
    </>
  );
}

LinkItem.defaultProps = {
  name: "",
  url: "",
};

LinkItem.propTypes = {
  name: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};
